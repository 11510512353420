import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { RiDeleteBin6Line } from "react-icons/ri";
import { saveAs } from 'file-saver';
import { SiMicrosoftexcel } from "react-icons/si";

export const Participants = () => {
  const [viewParticipants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
 const [lastPage, setLastPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [toPage, setToPage] = useState(0);
  const [fromPage, setFromPage] = useState(0);

  const getCsrfToken = async () => {
    await axios.get("/sanctum/csrf-cookie");
  };

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        await getCsrfToken();
       
        const res = await  axios.get(`api/all-participants?page=${currentPage}`);
        if (res.status === 200) {
          setParticipants(res.data.participants);
          setLastPage(res.data.pagination.last_page);          
          setCurrentPage(res.data.pagination.current_page);
          setFromPage(res.data.pagination.from);
          setToPage(res.data.pagination.to);
          setTotalPage(res.data.pagination.total);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching participants:', error);
      }
    };
    fetchParticipants();
  },  [currentPage]);
  const nextPage = () => {
   setCurrentPage(currentPage + 1);
 };
 
 const prevPage = () => {
   setCurrentPage(currentPage - 1);
 };

  const handleDownload = async () => {
    try {
      await getCsrfToken();
      const res = await axios.get(`/api/export-participants`, {
        responseType: 'blob',
        withCredentials: true
      });
      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'participants.xlsx');
    } catch (error) {
      console.error('Error downloading participants:', error);
    }
  };

  const filteredParticipants = viewParticipants.filter((item) =>
    Object.values(item).some(
      (field) =>
        typeof field === "string" &&
        field.toLowerCase().includes(searchInput.toLowerCase())
    )
  );

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this participant?")) {
      try {
        await axios.delete(`/api/delete-participant/${id}`);
        swal("Success", "Participant deleted successfully", "success");
        setParticipants((prevState) => prevState.filter((item) => item.id !== id));
      } catch (error) {
        console.error("Error deleting participant:", error);
      }
    }
  };

  

  if (loading) {
    return (
      <div className="text-center max-w-screen-xl max-h-screen-[72] mx-auto justify-center items-center">
        <div role="status" className="mt-[20rem]">
          <svg
            aria-hidden="true"
            className="inline w-16 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Participant Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex mx-3 mt-2 flex-col">
      <div className="md:flex items-center justify-between mx-4 my-2 sm:mt-2">
        <div className="flex md:justify-start md:items-start text-center">
          <h2 className="text-gray-600 mt-2 lg:mt-8 md:text-xl text-sm font-bold text-center">
            Participants
          </h2>
        </div>
        <div className=" flex space-x-8 justify-end items-end pt-2 sm:pt-4 md:pt-4 lg:pt-5 pr-3">
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="border px-2 py-1 rounded"
          />
          <div
            className="flex bg-green-500 text-sm   hover:bg-green-700 text-white font-bold py-1 px-2  rounded cursor-pointer"
            onClick={handleDownload}
          >
            < SiMicrosoftexcel  className="w-6 h-6"/>
          </div>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow md:overflow-hidden overflow-x-auto border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    S/N
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                  >
                    REG No:
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    City
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Job Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Organization
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredParticipants.map((item, i) => (
                  <tr key={i}>
                    <td className="pl-6 py-4 whitespace-nowrap text-start">
                      <div className="text-[12px] text-gray-900">{i + 1}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-[12px] font-medium text-gray-900">
                        {item.registration_number}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-[12px] text-gray-500">
                        {item.firstname} {item.surname}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-[12px] text-gray-500">
                        {item.email}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-[12px] text-gray-500">
                        {item.city}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-[12px] text-gray-500">
                        {item.jobtitle}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-[12px] text-gray-500">
                        {item.orgnization}
                      </div>
                    </td>
                    <td className="pr-6 py-4 whitespace-nowrap text-[12px]">
                      <Link to="#" className="text-indigo-600 hover:text-indigo-900">
                        {item.phone}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-[12px]">
                      <button
                        onClick={() => handleDelete(item.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <RiDeleteBin6Line className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex font-medium text-xs justify-center items-center space-x-4 m-2">
            <div>{` ${fromPage} - ${toPage} of ${totalPage} `}</div>
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className="bg-custom-green disabled:hidden bg-custom-dark-green rounded-sm p-1 text-white"
                >
                  Previous
                </button>
                <button
                  onClick={nextPage}
                  disabled={currentPage === lastPage}
                  className="bg-custom-green disabled:hidden bg-custom-dark-green rounded-sm p-1 text-white"
                >
                  Next
                </button>
              </div>
            
        </div>
      </div>
    </div>
  );
};
